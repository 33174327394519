import styled from 'styled-components';

import theme from '../theme';

export default styled.section`
  margin: 0 auto;
  max-width: ${({ large }) => (large ? '1220px' : '1100px')};
  padding-top: ${({ spacer }) => (spacer ? theme.sizing.scale500 : 0)};
  padding-bottom: ${({ spacer }) => (spacer ? theme.sizing.scale500 : 0)};
  padding-left: ${theme.sizing.scale200};
  padding-right: ${theme.sizing.scale200};
  position: relative;
  width: 100%;

  @media (max-width: ${theme.breakpoints.medium}) {
    padding-top: ${({ spacer }) => (spacer ? theme.sizing.scale400 : 0)};
    padding-bottom: ${({ spacer }) => (spacer ? theme.sizing.scale400 : 0)};
  }
`;
