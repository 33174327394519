import gql from 'graphql-tag';

import fragments from './fragments';

export const PAGE_BY_SLUG = gql`
  query pageBySlug($locale: String, $slug: String, $pageType: Page_PageType) {
    allPage(
      locale: $locale
      where: { slug: { eq: $slug }, pageType: { eq: $pageType } }
      first: 1
    ) {
      edges {
        node {
          id
          title
          seoDescription
          seoImage
          showTakeAway
          takeAwaySection
          images {
            image
            label
          }
          content {
            ...SliderItem
            ...ArticleItem
            ...GalleryItem
            ...UnknownItem
          }
        }
      }
    }
  }
  ${fragments.slider}
  ${fragments.article}
  ${fragments.gallery}
  ${fragments.unknown}
`;
