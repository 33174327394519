import React from 'react';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { createHttpLink } from 'apollo-link-http';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import introspectionResult from '../fragmentTypes';

const initialState = window.__APOLLO_STATE__ || null;

delete window.__APOLLO_STATE__;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ fragmentMatcher }).restore(initialState)
});

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __APOLLO_STATE__: client.extract()
});

export default ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;
