import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import theme from '../theme';
import { pageTypes } from '../lib/page';

import Button from '../components/Button';
import LinkLocale from '../components/LinkLocale';
import Paragraph from '../components/Paragraph';
import Title from '../components/Title';

const Main = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.sizing.scale400};
  position: absolute;
  width: 100%;
`;

export default () => {
  return (
    <Main>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Title content="Oops" />
      <Paragraph content="The item you are looking for isn't on the menu." />
      <Button as={LinkLocale} pageType={pageTypes.home}>Go back</Button>
    </Main>
  );
};
