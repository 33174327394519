import React, { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import slugify from '@sindresorhus/slugify';

import { PAGE_BY_SLUG } from '../gql/page';
import { SETTINGS_GLOBAL } from '../gql/settings';
import { useStateContext } from '../lib/context';
import { useLocale, useRouter } from '../lib/hooks';
import { getPageType, isHome, removeProtocol } from '../lib/page';

import Error from './Error';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Slider from '../components/Slider';
import { ModalContent as ModalReservations } from '../components/ModalReservations';

export default memo(() => {
  const {
    match: {
      params: { slug },
    },
  } = useRouter();
  const [{ settings }, dispatch] = useStateContext();
  const locale = useLocale();
  const [canAnimHeader, setCanAnimHeader] = useState(false);
  const pageType = getPageType(slug);

  const {
    loading: loadingSettings,
    data: dataSettings,
    error: errorSettings,
  } = useQuery(SETTINGS_GLOBAL, {
    variables: {
      locale,
    },
  });

  const { loading, data, error } = useQuery(PAGE_BY_SLUG, {
    skip: !dataSettings || Object.keys(dataSettings).length === 0,
    variables: {
      locale,
      slug,
      pageType,
    },
  });

  useEffect(() => {
    if (dataSettings) {
      const { Settings } = dataSettings;

      if (Settings && Settings !== settings) {
        dispatch({
          type: 'changeSettings',
          newSettings: Settings,
        });
      }
    }
  }, [dataSettings, dispatch, settings]);

  if (loading || loadingSettings) {
    return <Loader />;
  }

  if (error || errorSettings || !data) {
    return <Error />;
  }

  if (!data.allPage.edges.length) {
    return <Redirect to="404" />;
  }

  const {
    title,
    content,
    seoDescription,
    seoImage,
    images,
    showTakeAway,
    takeAwaySection,
  } = data.allPage.edges[0].node;
  const pageTitle = isHome(pageType)
    ? settings.siteName
    : `${title} | ${settings.siteName}`;
  const sections = content.map((area) => {
    const slug = area.label && slugify(area.label);
    return { ...area, slug };
  });

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:image" content={removeProtocol(seoImage)} />
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={seoDescription} />
      </Helmet>
      <Header
        areas={sections.filter(({ slug }) => slug)}
        canAnim={canAnimHeader}
      />
      <main>
        <Hero
          images={images}
          takeAwayButton={{
            display: showTakeAway,
            slug: sections.find(({ label }) => label === takeAwaySection).slug,
          }}
          onAnimationEnd={() => setCanAnimHeader(true)}
        />
        {sections &&
          sections.map((section, i) => {
            switch (section.__typename) {
              case 'Slider':
                return <Slider key={i} {...section} />;
              case 'Article':
                return <Article key={i} {...section} />;
              case 'Gallery':
                return <Gallery key={i} {...section} />;
              default:
                return null;
            }
          })}
      </main>
      <Footer />
      <ModalReservations />
    </>
  );
});
