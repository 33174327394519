import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { animated } from "react-spring";

import theme from "../theme";
import { useMarkdownParser } from "../lib/hooks";

import overline from "../assets/overline.svg";
import lines from "../assets/lines.svg";

const StyledTitle = styled(animated.h3)`
  flex-shrink: 0;
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font800};
  letter-spacing: .055em;
  line-height: 1;
  padding-bottom: ${theme.sizing.scale100};
  padding-top: 0.05em;
  position: relative;
  text-align: ${({ alt }) => (alt ? "center" : "inherit")};
  text-transform: uppercase;
  white-space: pre-line;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font700};
  }

  &::before {
    background-image: url(${lines});
    background-position: ${({ alt }) => (alt ? "center" : "0 center")};
    background-repeat: no-repeat;
    background-size: auto 100%;
    bottom: 100%;
    content: "";
    display: block;
    height: 11px;
    margin-bottom: ${theme.sizing.scale100};
    position: absolute;
    width: 100%;

    @media (max-width: ${theme.breakpoints.small}) {
      bottom: auto;
      position: relative;
    }
  }

  strong {
    color: ${({ alt }) => (alt ? "white" : "inherit")};
    display: inline-flex;
    position: relative;
    transform-style: preserve-3d;

    &:last-child {
      margin-bottom: ${theme.sizing.scale100};
    }

    &::after {
      background-color: ${({ alt }) =>
        alt ? theme.colors.primary : theme.colors.secondary};
      content: "";
      display: block;
      height: calc(100% + 1rem);
      left: 50%;
      mask-image: url(${overline});
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, -5px);
      width: 130%;
    }
  }
`;

const Title = ({ alt, content, ...rest }) => {
  const __html = useMarkdownParser(content, true);

  return (
    <StyledTitle
      dangerouslySetInnerHTML={{ __html }}
      alt={alt ? 1 : 0}
      {...rest}
    />
  );
};

Title.propTypes = {
  content: PropTypes.string.isRequired,
  alt: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf([0, 1])
  ])
};

export default memo(Title);
