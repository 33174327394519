import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { StateProvider } from './lib/context';
import { useRouter } from './lib/hooks';
import { getLocalesString } from './lib/locale';

import theme, { fontFaces } from './theme';

import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Page from './pages/Page';

const fontFaceCss = fontFaces
  .map(
    ({ name, location, weight }) => `@font-face {
    font-family: '${name}';
    src: url(${location}) format("woff");
    font-weight: ${weight};
  }`
  )
  .join(' ');

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fontFaceCss}
  html {
    -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
    -webkit-overflow-scrolling: auto; /* stop scrolling immediately */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.typography.families.body};
    font-size: ${theme.typography.baseFontSize}px;
    height: 100%;
    scroll-behavior: smooth;
    scroll-snap-align: start;

    @media (max-width: ${theme.breakpoints.small}) {
      font-size: ${theme.typography.baseFontSizeMobile}px;
    }
  }
  #root {
    box-sizing: border-box;
    color: ${theme.colors.dark};
    line-height: ${theme.typography.baseLineHeight};
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  a {
    border: none;
    color: currentcolor;
    text-decoration: none;

    &:hover,
    &:focus {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      outline: 0;
    }
  }
  button {
    appearance: none;
    border: none;
    color: currentcolor;
    cursor: pointer;
    font-family: inherit;
    padding: 0;

    &:hover,
    &:focus {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      outline: 0;
    }
  }
`;

const Fallback = styled.div`
  background-color: #fff;
  display: block;
  height: 100%;
  padding: 20px;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;

const localesList = getLocalesString();

export default () => {
  const { location } = useRouter();

  useEffect(() => {
    document.getElementById('root').scrollTop = 0;
    document.body.removeAttribute('style');
  }, [location.pathname]);

  const initialState = {
    settings: [],
    showReservations: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeSettings':
        return {
          ...state,
          settings: action.newSettings,
        };

      case 'toggleReservations':
        return {
          ...state,
          showReservations: action.open,
        };

      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <GlobalStyle />
      {window.document.documentMode ? (
        <Fallback>Please use a modern browser to view this website.</Fallback>
      ) : (
        <Switch>
          <Route path="/404" exact component={NotFound} />
          <Route path="/500" exact component={Error} />
          <Route
            path={`/:locale(${localesList})?/:slug?`}
            render={(props) => <Page key={location.pathname} {...props} />}
          />
        </Switch>
      )}
    </StateProvider>
  );
};
