import styled from 'styled-components';
import { animated } from 'react-spring';

import theme from '../theme';

export const Modal = styled(animated.section)`
  align-items: center;
  background-color: ${theme.colors.dark};
  display: flex;
  left: 0;
  height: 100%;
  justify-content: center;
  position: fixed;
  transform-style: preserve-3d;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const ModalClose = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0;
  height: ${theme.sizing.scale200};
  position: absolute;
  right: ${theme.sizing.scale200};
  top: ${theme.sizing.scale200};
  transition: color ${theme.transitions.duration} ${theme.transitions.easing};
  width: ${theme.sizing.scale200};
  z-index: 2000;

  &::before,
  &::after {
    background-color: currentcolor;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    margin-top: -4px;
    position: absolute;
    top: 50%;
    transform: translateY(400%) rotate(-45deg);
    transition: color ${theme.transitions.duration} ${theme.transitions.easing};
    width: 100%;
  }

  &::after {
    margin-top: 4px;
    transform: translateY(-400%) rotate(45deg);
  }

  &:hover,
  &:focus {
    color: ${theme.colors.secondary};
    outline: 0;
  }
`;

export const ModalWrap = styled.section`
  background-color: #fff;
  height: calc(100% - ${theme.sizing.scale500});
  max-height: 555px;
  max-width: 520px;
  overflow: auto;
  width: calc(100% - ${theme.sizing.scale300});

  @media only screen and (min-width: 517px) {
    max-height: 579px;
  }
`;
