import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';
import { useStateContext } from '../lib/context';
import { useIntersectionObserver } from '../lib/hooks';

import Button from './Button';
import ButtonIcon from './ButtonIcon';
import Figure from './Figure';
import Label from './Label';
import LinkExternal from './LinkExternal';
import Paragraph from './Paragraph';
import Title from './Title';
import Wrap from './Wrap';

import texture from '../assets/texture.jpg';

const StyledWrap = styled(Wrap)`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    background-image: ${({ alt }) => (alt ? `url(${texture})` : 'none')};
    background-size: cover;
    content: '';
    display: ${({ alt }) => (alt ? 'block' : 'none')};
    height: calc(100% + ${theme.sizing.scale700});
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    z-index: -1;

    @media (max-width: ${theme.breakpoints.small}) {
      display: none;
    }
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-auto-flow: ${({ column }) => (column ? 'row' : 'column')};
  grid-column-gap: ${theme.sizing.scale400};
  max-width: ${({ alt }) => (alt ? '800px' : '100%')};
  padding-top: ${({ alt }) => (alt ? theme.sizing.scale400 : 0)};
  transform-style: preserve-3d;
  width: 100%;

  @media (max-width: ${theme.breakpoints.medium}) {
    grid-auto-flow: row;
    padding-top: 0;
  }
`;

const StyledFooter = styled(animated.footer)`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-wrap: wrap;
`;

const StyledFooterSection = styled.section`
  padding-bottom: ${theme.sizing.scale200};
  padding-right: ${theme.sizing.scale500};

  @media (max-width: ${theme.breakpoints.medium}) {
    padding-right: ${theme.sizing.scale200};
  }

  &:last-child {
    padding-bottom: 0;
    padding-right: 0;
  }
`;

const StyledLabel = styled.h4`
  color: ${({ alt }) => (alt ? theme.colors.primary : 'inherit')};
  font-size: ${theme.typography.font300};
  font-weight: ${theme.typography.weights.bold};
  padding-top: ${({ spacer }) => (spacer ? theme.sizing.scale200 : 0)};
`;

const StyledInfo = styled.p`
  color: ${({ large }) => large ? theme.colors.default : 'inherit'};
  display: block;
  font-size: ${({ large }) =>
    large ? theme.typography.font600 : theme.typography.font300};
  font-weight: ${({ bold }) =>
    bold ? theme.typography.weights.bold : 'inherit'};
`;

const StyledAddress = styled.p`
  font-size: ${theme.typography.font400};
  white-space: pre-line;

  &:first-line {
    font-family: ${theme.typography.families.title};
    font-size: ${theme.typography.font600};
  }
`;

const StyledFigure = styled(Figure)`
  flex-shrink: 0;
  margin-left: ${theme.sizing.scale200};
  width: 64%;

  @media (max-width: ${theme.breakpoints.medium}) {
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

const layouts = {
  column: 'Column',
  row: 'Row'
};

const Article = ({
  hasBackground,
  image,
  imageMobile,
  label,
  layout,
  showContacts,
  showTakeAway,
  showMenuLink,
  slug,
  title,
  text,
  truncateText
}) => {
  const { fadeIn } = theme.animations;
  const contentRef = useRef();
  const active = useIntersectionObserver(contentRef, 0.4);
  const isColumn = layout === layouts.column;
  const [
    {
      settings: {
        phoneDetails,
        emailDetails,
        addressDetails,
        directionsLabel,
        directionsUrl,
        scheduleLabel,
        scheduleDetails,
        takeAwayLabel,
        takeAwayDetails,
        orderLabel,
        orderUrl,
        menuLabel,
        menuUrl
      }
    }
  ] = useStateContext();

  const animTitle = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.molasses
  });
  const animText = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.molasses,
    delay: 300
  });

  return (
    <StyledWrap
      id={slug}
      spacer={hasBackground ? 0 : 1}
      alt={hasBackground ? 1 : 0}
    >
      <StyledContent
        alt={hasBackground ? 1 : 0}
        column={isColumn}
        ref={contentRef}
      >
        <Label top={hasBackground ? `-${theme.sizing.scale600}` : null}>
          {label}
        </Label>
        <Title content={title} alt={hasBackground ? 1 : 0} style={animTitle} />
        {text && (
          <Paragraph
            content={text}
            columns={isColumn && image ? 1 : 2}
            style={animText}
            truncate={truncateText}
          />
        )}
        {showTakeAway && (
          <StyledFooter style={animTitle} column>
            <Button as={LinkExternal} href={orderUrl}>
              {orderLabel}
            </Button>
            <StyledLabel alt>{takeAwayLabel}</StyledLabel>
            <StyledInfo large as="a" href={`tel:${takeAwayDetails}`}>
              {takeAwayDetails}
            </StyledInfo>
          </StyledFooter>
        )}
        {showMenuLink && (
          <StyledFooter style={animTitle}>
            <Button as={LinkExternal} href={menuUrl}>
              {menuLabel}
            </Button>
          </StyledFooter>
        )}
        {showContacts && (
          <StyledFooter style={animTitle}>
            <StyledFooterSection>
              <StyledInfo bold as="a" href={`tel:${phoneDetails}`}>
                {phoneDetails}
              </StyledInfo>
              <StyledInfo bold as="a" href={`mailto:${emailDetails}`}>
                {emailDetails}
              </StyledInfo>
              <StyledLabel spacer>{scheduleLabel}</StyledLabel>
              <StyledInfo>{scheduleDetails}</StyledInfo>
            </StyledFooterSection>
            <StyledFooterSection>
              <StyledAddress>{addressDetails}</StyledAddress>
              <ButtonIcon icon="pin" as={LinkExternal} href={directionsUrl}>
                {directionsLabel}
              </ButtonIcon>
            </StyledFooterSection>
          </StyledFooter>
        )}
      </StyledContent>
      {image && <StyledFigure image={image} imageMobile={imageMobile} cover />}
    </StyledWrap>
  );
};

Article.propTypes = {
  hasBackground: PropTypes.bool.isRequired,
  image: PropTypes.string,
  label: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(layouts)).isRequired,
  showContacts: PropTypes.bool.isRequired,
  showTakeAway: PropTypes.bool.isRequired,
  showMenuLink: PropTypes.bool.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  truncateText: PropTypes.bool.isRequired
};

export default memo(Article);
