import React, { useRef, memo, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';
import { useIntersectionObserver } from '../lib/hooks';

import Figure from './Figure';
import Label from './Label';
import Wrap from './Wrap';

const SLIDE_MARGIN = theme.sizing.scale200;
const SLIDE_MARGIN_ABSOLUTE =
  parseInt(SLIDE_MARGIN) * theme.typography.baseFontSize;

const SliderWrap = styled.div`
  position: relative;
`;

const SlidesWrap = styled.div`
  clip-path: polygon(0% 0%, 0% 100%, 100vw 100%, 100vw 0);
`;

const SliderCounter = styled(animated.aside)`
  align-items: flex-end;
  color: ${theme.colors.primaryFaded};
  bottom: 0;
  display: flex;
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font400};
  letter-spacing: 0.05rem;
  line-height: 1;
  position: absolute;
  right: calc(100% + ${theme.sizing.scale100});
  user-select: none;

  @media (max-width: ${theme.breakpoints.large}) {
    display: none;
  }

  &::after {
    background-color: ${theme.colors.secondary};
    content: '';
    display: block;
    height: 100%;
    left: 53%;
    position: absolute;
    top: 0;
    transform: rotate(15deg);
    width: 2px;
  }
`;

const SliderCurrent = styled.span`
  align-self: flex-start;
  color: ${theme.colors.primary};
  display: flex;
  justify-content: flex-end;
  font-size: ${theme.typography.font600};
  padding-bottom: ${theme.sizing.scale100};
  margin-right: ${theme.sizing.scale100};
  white-space: nowrap;
  width: 2ch;
`;

const SliderItems = styled.section`
  cursor: grab;
  display: flex;
  transition: transform ${theme.transitions.durationMedium}
    ${theme.transitions.easingAlt};
`;

const SliderItem = styled(Figure)`
  max-height: 550px;
  margin-right: ${SLIDE_MARGIN};
  flex-shrink: 0;
  width: calc(100% - ${SLIDE_MARGIN});

  @media (max-width: ${theme.breakpoints.large}) {
    width: 100%;
  }
`;

const SliderNav = styled(animated.div)`
  display: none;
  justify-content: center;
  margin-top: ${theme.sizing.scale100};

  @media (max-width: ${theme.breakpoints.large}) {
    display: flex;
    width: 100%;
  }
`;

const SliderButton = styled.button`
  background-color: ${theme.colors.default};
  border-radius: 50%;
  height: 7px;
  margin-right: ${theme.sizing.scale100};
  transition: background-color ${theme.transitions.duration}
    ${theme.transitions.easing};
  width: 7px;

  &:hover,
  &:focus,
  &.active {
    background-color: ${theme.colors.secondary};
  }
`;

const Slider = ({ label, slides = [], slug }) => {
  const { fadeIn } = theme.animations;
  const sliderRef = useRef(null);
  const slideRef = useRef(null);
  const active = useIntersectionObserver(sliderRef);
  const [activeSlide, setActiveSlide] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [canMove, setCanMove] = useState(false);
  const [initialPos, setInitialPos] = useState(0);

  const animContent = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.gentle
  });

  const getCurrentSlide = pos => {
    const offset = 10;

    if (pos > initialPos + offset) {
      return Math.max(activeSlide - 1, 0);
    }

    if (pos < initialPos - offset) {
      return Math.min(activeSlide + 1, slides.length - 1);
    }

    return activeSlide;
  };

  const updateSlider = pos => {
    const active = getCurrentSlide(pos);

    if (active !== activeSlide && canMove) {
      setActiveSlide(active);
      setCanMove(false);
    }
  };

  const getWidth = () => {
    if (slideRef.current) {
      setItemWidth(slideRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', getWidth, true);

    return () => window.removeEventListener('resize', getWidth, true);
  }, [slideRef]);

  return slides ? (
    <Wrap id={slug} spacer ref={sliderRef}>
      <SliderWrap>
        <Label style={animContent} top={theme.sizing.scale600}>
          {label}
        </Label>
        <SliderCounter aria-hidden="true" style={animContent}>
          <SliderCurrent>{('0' + (activeSlide + 1)).slice(-2)}</SliderCurrent>
          {('0' + slides.length).slice(-2)}
        </SliderCounter>
        <SlidesWrap>
          <SliderItems
            onTouchStart={e => {
              const touch = e.touches || e.changedTouches;
              setInitialPos(touch[0].clientX);
              setCanMove(true);
            }}
            onTouchMove={e => {
              const touch = e.touches || e.changedTouches;
              updateSlider(touch[0].clientX);
            }}
            onTouchEnd={() => setCanMove(false)}
            onMouseDown={e => {
              e.preventDefault();

              setInitialPos(e.clientX);
              setCanMove(true);
            }}
            onMouseMove={e => updateSlider(e.clientX)}
            onMouseUp={() => setCanMove(false)}
            style={{
              transform: `translateX(-${(itemWidth + SLIDE_MARGIN_ABSOLUTE) *
                activeSlide}px)`
            }}
          >
            {slides.map(({ image }, i) => (
              <SliderItem
                key={i}
                ref={slideRef}
                image={image}
                onRest={() => getWidth()}
                cover
              />
            ))}
          </SliderItems>
        </SlidesWrap>
        <SliderNav aria-hidden="true" style={animContent}>
          {slides.map((_, i) => (
            <SliderButton
              key={i}
              className={activeSlide === i ? 'active' : ''}
              onClick={() => setActiveSlide(i)}
            ></SliderButton>
          ))}
        </SliderNav>
      </SliderWrap>
    </Wrap>
  ) : null;
};

Slider.propTypes = {
  label: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape(
      {
        image: PropTypes.string
      }.isRequired
    )
  ),
  slug: PropTypes.string
};

export default memo(Slider);
