import React from 'react';
import PropTypes from 'prop-types';

const LinkExternal = ({ children, href, ...rest }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
    {children}
  </a>
);

LinkExternal.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
};

export default LinkExternal;
