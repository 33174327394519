import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';
import { useStateContext } from '../lib/context';

import LinkExternal from './LinkExternal';
import LocaleSwitch from './LocaleSwitch';
import SocialNav from './SocialNav';
import Title from './Title';
import Wrap from './Wrap';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Tripadvisor } from '../assets/tripadvisor.svg';
import { ReactComponent as Zomato } from '../assets/zomato.svg';

const StyledFooter = styled.footer`
  background-color: ${theme.colors.primary};
  font-family: 'Open Sans', sans-serif;
  font-size: ${theme.typography.font100};
  line-height: 1;
  margin-top: ${theme.sizing.scale600};
  padding: ${theme.sizing.scale200} 0 ${theme.sizing.scale100};
  position: relative;

  @media (max-width: ${theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

const StyledWrap = styled(Wrap)`
  color: rgba(255, 255, 255, 0.4);
  display: grid;
  grid-gap: ${theme.sizing.scale100};
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${theme.breakpoints.medium}) {
    align-items: end;
    grid-row-gap: 2rem;
  }
`;

const StyledSection = styled.aside`
  align-items: center;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  justify-content: start;

  @media (max-width: ${theme.breakpoints.medium}) {
    grid-auto-flow: row;
    grid-row: ${({ small }) => (small ? '2' : 'inherit')};
  }
`;

const StyledAside = styled(StyledSection)`
  justify-self: end;

  @media (max-width: ${theme.breakpoints.medium}) {
    grid-auto-flow: column;
    grid-column: 1 / -1;
    justify-content: space-between;
    justify-self: stretch;
  }
`;

const StyledTitle = styled(Title)`
  font-size: ${theme.typography.font600};
  bottom: 100%;
  left: 50%;
  position: absolute;
  text-transform: none;
  transform: translateX(-50%);

  @media (max-width: ${theme.breakpoints.medium}) {
    color: #fff;
    left: auto;
    margin-bottom: ${theme.sizing.scale400};
    position: relative;
    transform: none;
  }
`;

const StyledLogo = styled(Logo)`
  @media (max-width: ${theme.breakpoints.medium}) {
    grid-column: 1 / -1;
    justify-self: center;
  }
`;

const StyledSocial = styled(SocialNav)`
  padding-left: ${theme.sizing.scale400};

  @media (max-width: ${theme.breakpoints.medium}) {
    justify-self: end;
  }
`;

const StyledLocaleSwitch = styled(LocaleSwitch)`
  grid-column: ${({ small }) => (small ? 'inherit' : '1 / -1')};
  justify-self: end;
  padding-bottom: ${theme.sizing.scale200};

  @media (max-width: ${theme.breakpoints.medium}) {
    padding-bottom: 0;
  }
`;

const StyledLabel = styled.p`
  justify-self: end;
`;

const StyledLinkFade = styled(LinkExternal)`
  transition: opacity ${theme.transitions.duration} ${theme.transitions.easing};

  &:hover,
  &:focus {
    opacity: .5;
  }
`;

const StyledLink = styled(LinkExternal)`
padding-right: ${theme.sizing.scale100};
  transition: color ${theme.transitions.duration} ${theme.transitions.easing};

  &:hover,
  &:focus {
    color: #fff;
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    padding: ${theme.sizing.scale100} 0 0;
  }
`;

const Footer = ({ alt = false, ...props }) => {
  const [
    {
      settings: {
        complaintsLabel,
        complaintsUrl,
        privacyPolicyLabel,
        privacyPolicyUrl,
        tripadvisorUrl,
        zomatoUrl
      }
    }
  ] = useStateContext();

  return (
    <StyledFooter {...props}>
      {!alt && <StyledTitle content="Buon appetito!" alt />}
      <StyledWrap>
        {!alt && <StyledLogo />}
        <StyledAside>
          <StyledAside as="div">
            <StyledLinkFade href={tripadvisorUrl}>
              <Tripadvisor />
            </StyledLinkFade>
            <StyledLinkFade href={zomatoUrl}>
              <Zomato />
            </StyledLinkFade>
          </StyledAside>
          <StyledSocial alt />
        </StyledAside>
        <StyledLocaleSwitch alt small={alt ? 1 : 0} />
        <StyledSection as="nav" small={alt ? 1 : 0}>
          <StyledLink href={privacyPolicyUrl || '#'}>
            {privacyPolicyLabel}
          </StyledLink>
          <StyledLink href={complaintsUrl}>{complaintsLabel}</StyledLink>
        </StyledSection>
        {!alt && <StyledLabel>made by duall</StyledLabel>}
      </StyledWrap>
    </StyledFooter>
  );
};

Footer.propTypes = {
  alt: PropTypes.bool
};

export default memo(Footer);
