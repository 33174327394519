import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import { ReactComponent as Bike } from '../assets/bike.svg';
import { ReactComponent as Pin } from '../assets/pin.svg';

const StyledButton = styled.a`
  align-items: center;
  background-color: transparent;
  color: currentcolor;
  display: inline-flex;
  font-size: ${theme.typography.font300};
  padding: ${theme.sizing.scale100} 0;
`;

const StyledIcon = styled.svg`
  margin-right: .5rem;
`;

const Button = ({ children, icon, ...rest }) => {
  const Icon = {
    pin: Pin,
    bike: Bike
  }[icon];

  return (
    <StyledButton {...rest}>
      <StyledIcon as={Icon} />
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(['bike', 'pin']).isRequired
};

export default memo(Button);
