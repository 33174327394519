import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useChain, useSpring, animated, config } from 'react-spring';

import theme from '../theme';

import { useIntersectionObserver } from '../lib/hooks';
import { removeProtocol } from '../lib/page';

const StyledFigure = styled(animated.picture)`
  display: inline-block;
  overflow: hidden;
  position: relative;

  @media (max-width: ${theme.breakpoints.small}) {
    display: block;
  }
`;

const StyledOverlay = styled(animated.span)`
  background-color: ${({ colors }) => getRandomFromArray(colors)};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledImg = styled.img`
  display: block;
  height: ${({ cover }) => (cover ? '100%' : 'auto')};
  margin: 0;
  max-width: 100%;
  object-fit: cover;
  user-select: none;
  width: ${({ cover }) => (cover ? '100%' : 'auto')};

  @media (max-width: ${theme.breakpoints.small}) {
    width: 100%;
  }
`;

const getRandomFromArray = array =>
  array[Math.floor(Math.random() * array.length)];

const Figure = (
  { cover = false, image, imageMobile, label, onRest = () => {}, ...rest },
  ref
) => {
  const {
    fadeUp,
    scaleBottom,
    scaleLeft,
    scaleRight,
    scaleTop
  } = theme.animations;
  const [layerAnim] = useState(
    getRandomFromArray([scaleBottom, scaleLeft, scaleRight, scaleTop])
  );

  const figureRef = useRef();
  const itemRef = ref ? ref : figureRef;
  const active = useIntersectionObserver(itemRef);
  const animFigureRef = useRef();
  const animFigure = useSpring({
    from: fadeUp.from,
    to: active ? fadeUp.to : fadeUp.from,
    config: config.molasses,
    onRest,
    ref: animFigureRef
  });

  const animLayerRef = useRef();
  const animLayer = useSpring({
    from: layerAnim.from,
    to: active ? layerAnim.to : layerAnim.from,
    config: config.slow,
    delay: 100,
    ref: animLayerRef
  });

  useChain([animFigureRef, animLayerRef], [0, 0.5]);

  return (
    <StyledFigure style={animFigure} ref={itemRef} {...rest}>
      <StyledOverlay
        style={animLayer}
        colors={[theme.colors.primary, theme.colors.secondary]}
      />
      {imageMobile && (
        <source
          srcSet={removeProtocol(imageMobile)}
          media={`(max-width: ${theme.breakpoints.medium})`}
        ></source>
      )}
      <StyledImg src={removeProtocol(image)} alt={label} cover={cover} />
    </StyledFigure>
  );
};

Figure.propTypes = {
  cover: PropTypes.bool,
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  label: PropTypes.string,
  onRest: PropTypes.func,
  ref: PropTypes.instanceOf(Element)
};

export default forwardRef(Figure);
