import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

const StyledLabel = styled.h2`
  font-size: ${theme.typography.font200};
  left: 50%;
  padding: 0 ${theme.sizing.scale300};
  position: absolute;
  top: ${({ top }) => (top ? top : '0.5em')};
  transform: translateX(-50%) scale(-1);
  user-select: none;
  writing-mode: vertical-rl;
  white-space: nowrap;
  width: 100vw;

  @media (max-width: ${theme.breakpoints.large}) {
    display: none;
  }
`;

const Label = ({ children, top }) => (
  <StyledLabel top={top}>{children}</StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  top: PropTypes.string
};

export default memo(Label);
