import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import { ReactComponent as Arrow } from '../assets/arrow.svg';

const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  color: currentcolor;
  display: inline-flex;
  font-size: ${theme.typography.font600};
  line-height: 1;
  padding: ${theme.sizing.scale100} 0;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font500};
  }
`;

const StyledIcon = styled(Arrow)`
  stroke: ${theme.colors.secondary};
  margin-left: ${theme.sizing.scale100};
  transition: all ${theme.transitions.duration} ${theme.transitions.easing};

  ${StyledButton}:hover &,
  ${StyledButton}:focus & {
    stroke: ${theme.colors.primary};
    transform: translateX(20%);
  }
`;

const Button = ({ children, ...rest }) => (
  <StyledButton {...rest}>
    {children}
    <StyledIcon alt={rest.alt ? 1 : 0} />
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired
};

export default memo(Button);
