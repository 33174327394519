import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useStateContext } from "../lib/context";

import theme from "../theme";

import LinkExternal from "./LinkExternal";

import { ReactComponent as Facebook } from "../assets/facebook.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";

const StyledSection = styled.nav`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: ${({ alt }) => (alt ? "column" : "row")};
  grid-column-gap: .5rem;
  grid-row-gap: 0.2rem;
`;

const StyledLabel = styled.span`
  font-family: "Merriweather", serif;
  font-size: ${theme.typography.font100};
  font-weight: ${theme.typography.weights.bold};
`;

const StyledLink = styled(LinkExternal)`
  transition: opacity ${theme.transitions.duration} ${theme.transitions.easing};

  &:hover,
  &:focus {
    opacity: .5;
  }
`;

const SocialNav = ({ alt = false, ...rest }) => {
  const [
    {
      settings: { facebookUrl, instagramUrl }
    }
  ] = useStateContext();

  return (
    <StyledSection alt={alt ? 1 : 0} {...rest}>
      {alt && <StyledLabel>#mangiamangiaPT</StyledLabel>}
      <StyledLink href={instagramUrl}>
        <Instagram />
      </StyledLink>
      <StyledLink href={facebookUrl}>
        <Facebook />
      </StyledLink>
    </StyledSection>
  );
};

SocialNav.propTypes = {
  alt: PropTypes.bool
};

export default memo(SocialNav);
