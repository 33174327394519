import gql from 'graphql-tag';

export const SETTINGS_GLOBAL = gql`
  query settingsGlobal($locale: String) {
    Settings(locale: $locale) {
      id
      siteName
      phoneLabel
      phoneDetails
      emailDetails
      emailLabel
      addressLabel
      directionsLabel
      scheduleDetails
      scheduleLabel
      directionsUrl
      takeAwayLabel
      takeAwayDetails
      addressDetails
      orderLabel
      orderUrl
      menuLabel
      menuUrl
      facebookUrl
      instagramUrl
      tripadvisorUrl
      zomatoUrl
      privacyPolicyLabel
      privacyPolicyUrl
      complaintsLabel
      complaintsUrl
      languageLabel
      readMoreLabel
      reservationsLabel
      reservationsUrl
      giftCardsLabel
      giftCardsUrl
    }
  }
`;
