import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

const FixedBox = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: centeR;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;

const Svg = styled.svg`
  fill: ${theme.colors.primary};
`;

const Loader = () => (
  <FixedBox>
    <Svg
      viewBox="0 0 18 18"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9h2a7 7 0 1 1 7 7v2a9 9 0 1 0-9-9z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 9 9"
          to="360 9 9"
          dur="0.7s"
          repeatCount="indefinite"
        />
      </path>
    </Svg>
  </FixedBox>
);

export default Loader;
