import gql from 'graphql-tag';

const fragmentsLinks = {
  internalLink: gql`
    fragment InternalLink on PageLink {
      title
      video
      image
      page(locale: $locale) {
        pageType
        title
        slug
      }
    }
  `,
  externalLink: gql`
    fragment ExternalLink on ExternalLink {
      title
      url
      video
      image
    }
  `,
  fileLink: gql`
    fragment FileLink on FileLink {
      title
      url
    }
  `,
  unknown: gql`
    fragment UnknownItem on Prime_UnknownSlice {
      error
      raw
    }
  `
};

export default {
  slider: gql`
    fragment SliderItem on Slider {
      label
      slides {
        image
      }
    }
  `,
  article: gql`
    fragment ArticleItem on Article {
      image
      imageMobile
      label
      title
      text
      showContacts
      showTakeAway
      showMenuLink
      layout
      hasBackground
      truncateText
    }
  `,
  gallery: gql`
    fragment GalleryItem on Gallery {
      label
      images {
        image
        alt
      }
    }
  `,
  ...fragmentsLinks
};
