import { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { __RouterContext } from 'react-router-dom';
import showdown from 'showdown';

import { getCurrLocale } from '../lib/locale';

export const useRouter = () => {
  return useContext(__RouterContext);
};

export const useLocale = () => {
  const {
    match: { params },
  } = useRouter();

  return getCurrLocale(params.locale);
};

export const useIntersectionObserver = (ref, threshold = 0.25) => {
  const [active, setActive] = useState(false);

  useLayoutEffect(() => {
    const item = ref.current;

    const unobserve = () => observer.unobserve(item);

    const handleIntersection = (entries) =>
      entries.forEach(({ target, boundingClientRect }) => {
        const insersectingX = boundingClientRect.x + 100 <= window.innerWidth; // check if at least 100 pixels are visible in x axis
        const insersectingY =
          boundingClientRect.y + boundingClientRect.height * threshold <=
          window.innerHeight; // check if intersection is bigger than threshold in y axis

        if (target === item && (insersectingY && insersectingX)) {
          setActive(true);
          unobserve();
        }
      });
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: [threshold / 2, threshold],
    });

    observer.observe(item);

    return () => unobserve();
  }, [ref, threshold]);

  return active;
};

export const useImageLoader = (url = null) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (url) {
      var img = new Image();

      img.onload = () => {
        setLoaded(true);
      };

      img.src = url;

      if (img.complete) {
        setLoaded(true);
      }
    }
  }, [url]);

  return loaded;
};

export const useMarkdownParser = (markdown, inline = false) => {
  const [html, setHtml] = useState(markdown);

  useEffect(() => {
    showdown.extension('exclude-paragraphs', () => [
      {
        type: 'output',
        filter: function(text) {
          // remove paragraphs
          text = text.replace(/<\/?p[^>]*>/g, '');

          return text;
        },
      },
    ]);

    const converter = new showdown.Converter({
      simpleLineBreaks: true,
      extensions: inline ? ['exclude-paragraphs'] : [],
    });

    setHtml(converter.makeHtml(markdown));
  }, [inline, markdown]);

  return html;
};

export const useAnimationScroll = () => {
  const [scrolled, setScrolled] = useState(0);

  useLayoutEffect(() => {
    let scrollEvent;

    const scroll = () => {
      const scrolled = window.pageYOffset;

      setScrolled(scrolled);

      loopRaf();
    };

    const loopRaf = () => {
      scrollEvent = window.requestAnimationFrame(scroll);
    };

    const stopRaf = () => {
      window.cancelAnimationFrame(scrollEvent);
    };

    loopRaf();

    return () => {
      stopRaf();
    };
  }, []);

  return scrolled;
};

export const useScrollButton = () => {
  const onClick = (e) => {
    e.preventDefault();

    const offset = 100;
    const elId = e.target.hash.replace('#', '');
    const itemTop = document.getElementById(elId).offsetTop;

    window.scroll({
      top: itemTop - offset,
      behavior: 'smooth',
    });
  };

  return onClick;
};
