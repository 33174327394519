import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';
import { useStateContext } from '../lib/context';
import { useAnimationScroll, useScrollButton } from '../lib/hooks';

import ButtonIcon from './ButtonIcon';
import LocaleSwitch from './LocaleSwitch';
import Footer from './Footer';
import { ModalButton as ReservationsButton } from '../components/ModalReservations';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as LogoSmall } from '../assets/logo-small.svg';
import { ReactComponent as Lines } from '../assets/lines.svg';

const LOGO_HEIGHT_MOBILE = '55px';

const StyledHeader = styled.header`
  align-items: center;
  color: #fff;
  display: grid;
  grid-gap: ${theme.sizing.scale100};
  grid-auto-flow: column;
  grid-template-columns: 1.3fr 1fr 1.3fr;
  opacity: 0;
  padding: ${theme.sizing.scale200} ${theme.sizing.scale300};
  position: fixed;
  transition: opacity ${theme.transitions.durationLong}
    ${theme.transitions.easing};
  width: 100%;
  z-index: 100;

  @media (max-width: ${theme.breakpoints.medium}) {
    padding: ${theme.sizing.scale100};
  }

  &::before {
    background-color: ${theme.colors.primary};
    box-shadow: 0 18px 70px 0 rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity ${theme.transitions.duration}
      ${theme.transitions.easing};
    width: 100%;
  }

  &.scrolled {
    padding-bottom: ${theme.sizing.scale100};
    padding-top: ${theme.sizing.scale100};

    &::before {
      opacity: 1;
      transition-delay: ${theme.transitions.duration};
    }
  }

  &.animated {
    opacity: 1;
  }
`;

const StyledLink = styled.a`
  font-weight: ${theme.typography.weights.bold};
  line-height: 1.5;
  margin-right: ${theme.sizing.scale200};
  position: relative;

  @media (max-width: ${theme.breakpoints.large}) {
    margin-right: 1rem;
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    font-size: ${theme.typography.font600};
    margin-right: 0;
    width: 100%;
  }
`;

const StyledLines = styled(Lines)`
  fill: ${theme.colors.secondary};
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  transform-origin: 0 0;
  transition: all ${theme.transitions.duration} ${theme.transitions.easing};

  ${StyledLink}:hover &,
  ${StyledLink}:focus &,
  ${StyledLink}.active & {
    opacity: 1;
    transform: translateX(0);

    @media (max-width: ${theme.breakpoints.medium}) {
      transform: translateX(0) scale(0.5);
    }
  }
`;

const StyledSectionRight = styled.div`
  align-items: center;
  display: grid;
  grid-gap: ${theme.sizing.scale200};
  grid-auto-flow: column;
  justify-self: end;
  position: relative;
  z-index: 100;

  @media (max-width: ${theme.breakpoints.large}) {
    grid-gap: 1rem;
  }
`;

const StyledDirections = styled(ButtonIcon)`
  font-size: ${theme.typography.font300};
  font-weight: ${theme.typography.weights.bold};
  padding: 0;

  @media (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const StyledLogoContainer = styled.div`
  justify-self: center;
  position: relative;

  @media (max-width: ${theme.breakpoints.medium}) {
    height: ${LOGO_HEIGHT_MOBILE};
  }
`;

const StyledLogo = styled(Logo)`
  opacity: 1;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
  transform-origin: center -30%;
  transition-delay: ${theme.transitions.duration};
  transition-duration: ${theme.transitions.duration};
  transition-property: opacity;
  transition-timing-function: ${theme.transitions.easing};

  @media (max-width: ${theme.breakpoints.large}) {
    top: 0;
    transform: translateX(-50%);
    transform-origin: center 0;
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    left: auto;
    max-height: ${LOGO_HEIGHT_MOBILE};
    position: relative;
    top: auto;
    transform: none;

    [data-label] {
      display: none;
    }
  }

  ${StyledHeader}.scrolled & {
    opacity: 0;
    transition-delays: 0s;

    @media (max-width: ${theme.breakpoints.medium}) {
      opacity: 1;
    }
  }
`;

const StyledLogoSmall = styled(LogoSmall)`
  opacity: 0;
  transition: opacity ${theme.transitions.duration} ${theme.transitions.easing};

  ${StyledHeader}.scrolled & {
    opacity: 1;
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const StyledMenuWrap = styled.section`
  @media (max-width: ${theme.breakpoints.medium}) {
    align-items: flex-start;
    background-color: ${theme.colors.primary};
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    left: 0;
    padding-bottom: ${theme.sizing.scale100};
    padding-top: ${theme.sizing.scale600};
    position: fixed;
    top: 0;
    transition: all ${theme.transitions.duration} ${theme.transitions.easing}
      ${theme.transitions.duration};
    width: 100%;
    z-index: 100;

    &[aria-hidden='true'] {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  width: 100%;

  @media (max-width: ${theme.breakpoints.medium}) {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 ${theme.sizing.scale200};
  }
`;

const StyledBurger = styled.button`
  display: none;

  @media (max-width: ${theme.breakpoints.medium}) {
    background-color: transparent;
    border-radius: 50%;
    display: block;
    height: 40px;
    justify-self: start;
    position: relative;
    transition: background-color ${theme.transitions.duration}
      ${theme.transitions.easing};
    width: 40px;
    z-index: 110;

    &::before,
    &::after {
      background-color: currentcolor;
      content: '';
      display: block;
      height: 2px;
      margin: 5px 0;
      transition: transform ${theme.transitions.duration}
        ${theme.transitions.easing} ${theme.transitions.duration};
      width: 50px;
    }

    &[aria-expanded='true'] {
      background-color: ${theme.colors.secondary};
      transition-delay: ${theme.transitions.duration};

      &::before {
        transform: translate(-10%, 160%) rotate(45deg) scaleX(0.5);
        transition-delay: 0s;
      }

      &::after {
        transform: translate(-10%, -160%) rotate(-45deg) scaleX(0.5);
        transition-delay: 0s;
      }
    }
  }
`;

const StyledLabel = styled.span`
  display: none;

  @media (max-width: ${theme.breakpoints.medium}) {
    display: block;
    font-family: ${theme.typography.families.title};
    font-size: ${theme.typography.font400};
    padding-bottom: ${theme.sizing.scale100};
    text-transform: uppercase;
  }
`;

const StyledFooter = styled(Footer)`
  display: none;
  align-self: flex-end;

  @media (max-width: ${theme.breakpoints.medium}) {
    display: block;
    width: 100%;
  }
`;

const ReservationsButtonDesktop = styled(ReservationsButton)`
  background-color: ${theme.colors.secondary};
  border-radius: 18px;
  color: ${theme.colors.primary};
  cursor: pointer;
  font-weight: ${theme.typography.weights.bold}
  padding: 0.4rem 1rem 0.3rem;
  text-transform: uppercase;
  transition: background-color ${theme.transitions.duration}
    ${theme.transitions.easing};

  @media (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: #fff;
  }
`;

const ReservationsButtonMobile = styled(ReservationsButtonDesktop)`
  display: none;

  @media (max-width: ${theme.breakpoints.medium}) {
    display: block;
    margin-top: 1rem;
  }
`;

const Header = ({ areas = [], canAnim }) => {
  const [
    {
      settings: {
        addressDetails,
        directionsUrl,
        giftCardsLabel,
        giftCardsUrl,
        reservationsLabel,
      },
    },
  ] = useStateContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const scrolled = useAnimationScroll();
  const scrollTo = useScrollButton();
  const isScrolled = scrolled > 0;

  return (
    <StyledHeader
      className={`${isScrolled ? 'scrolled' : ''} ${canAnim ? 'animated' : ''}`}
    >
      <StyledBurger
        aria-controls="main-menu"
        aria-expanded={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <StyledMenuWrap id="main-menu" aria-hidden={!menuOpen}>
        <StyledMenu>
          <StyledLabel>Menu</StyledLabel>
          {areas.map(({ label, slug }) => (
            <StyledLink
              href={`#${slug}`}
              key={slug}
              onClick={(e) => {
                scrollTo(e);
                setActiveItem(slug);
                setMenuOpen(false);
              }}
              className={activeItem === slug ? 'active' : ''}
            >
              {label}
              <StyledLines />
            </StyledLink>
          ))}
          <ReservationsButtonMobile>
            {reservationsLabel}
          </ReservationsButtonMobile>
        </StyledMenu>
        <StyledFooter alt />
      </StyledMenuWrap>
      <StyledLogoContainer>
        <StyledLogo />
        <StyledLogoSmall />
      </StyledLogoContainer>
      <StyledSectionRight>
        <StyledLink href={giftCardsUrl} style={{ marginRight: 0 }}>
          {giftCardsLabel}
          <StyledLines />
        </StyledLink>
        <ReservationsButtonDesktop>
          {reservationsLabel}
        </ReservationsButtonDesktop>
        {/* <StyledDirections
          icon="pin"
          href={directionsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {addressDetails && addressDetails.split('\n')[0]}
        </StyledDirections> */}
        <LocaleSwitch />
      </StyledSectionRight>
    </StyledHeader>
  );
};

Header.propTypes = {
  areas: PropTypes.array,
  canAnim: PropTypes.bool.isRequired,
};

export default Header;
