import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import Figure from './Figure';
import Label from './Label';
import Wrap from './Wrap';

const StyledWrap = styled(Wrap)`
  margin-top: -${theme.sizing.scale400};
  padding-top: 0;
`;

const Styledgrid = styled.div`
  align-content: center;
  align-items: center;
  display: grid;
  grid-gap: ${theme.sizing.scale200};
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;

  @media (max-width: ${theme.breakpoints.small}) {
    grid-gap: ${theme.sizing.scale100};
    grid-template-columns: 1fr;
  }
`;

const StyledItem = styled.div`
  @media (min-width: ${theme.breakpoints.small}) {
    grid-column: span 2;

    &:nth-child(2) {
      transform: translateY(35%);
      z-index: 1;
    }

    &:nth-child(3) {
      transform: translateY(-10%);
    }

    &:nth-child(3n) {
      grid-column: 1 / -1;
      justify-self: center;
    }

    &:nth-child(3n - 1) {
      justify-self: end;
    }

    &:nth-child(4n + 1) {
      grid-column: span 3;
    }

    &:nth-child(6n) {
      justify-self: end;
    }

    &:last-child {
      grid-column: span 3;
      justify-self: center;
    }
  }
`;

const Gallery = ({ images, label, slug }) => {
  return (
    <StyledWrap id={slug} large spacer>
      {label && <Label>{label}</Label>}
      <Styledgrid>
        {images.map(({ image }, i) => (
          <StyledItem key={i}>
            <Figure image={image} />
          </StyledItem>
        ))}
      </Styledgrid>
    </StyledWrap>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape(
      {
        image: PropTypes.string
      }.isRequired
    )
  ).isRequired,
  label: PropTypes.string,
  slug: PropTypes.string
};

export default memo(Gallery);
