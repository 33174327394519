import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pageTypes } from '../lib/page';
import { useStateContext } from '../lib/context';
import { getLocales } from '../lib/locale';
import { useLocale } from '../lib/hooks';

import theme from '../theme';

import LinkLocale from './LinkLocale';

const BORDER_RADIUS = '4px';
const SECTION_SPACING = '3px';

const StyledSection = styled.nav`
  align-items: center;
  display: inline-flex;
  font-size: ${({ alt }) =>
    alt ? theme.typography.font100 : theme.typography.font200};
  font-weight: ${theme.typography.weights.bold};
  line-height: 2;
`;

const StyledLabel = styled.span`
  color: #fff;
  padding-right: ${theme.sizing.scale100};
`;

const StyledList = styled.div`
  border-color: rgba(255, 255, 255, 0.4);
  border-width: ${({ alt }) => (alt ? 'none' : '1px')};
  border-radius: ${BORDER_RADIUS};
  border-style: solid;
  display: inline-flex;
  padding: ${({ alt }) => (alt ? 0 : SECTION_SPACING)};
`;

const StyledLocale = styled(LinkLocale)`
  border-radius: ${BORDER_RADIUS};
  margin-left: ${SECTION_SPACING};
  padding: 0 0.5rem;
  text-transform: uppercase;
  transition: all ${theme.transitions.duration} ${theme.transitions.easing};

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: ${({ alt }) => (alt ? 'transparent' : '#fff')};
    color: ${({ alt }) => (alt ? '#fff' : theme.colors.default)};
  }
`;

const localesArr = getLocales();

const LocaleSwitch = ({ alt = false, ...rest }) => {
  const locale = useLocale();
  const [
    {
      settings: { languageLabel }
    }
  ] = useStateContext();
  const isCurrLocale = (item, locale) => locale === item;

  return (
    <StyledSection alt={alt} {...rest}>
      {alt && <StyledLabel>{languageLabel}</StyledLabel>}
      <StyledList alt={alt}>
        {localesArr.map(item => (
          <StyledLocale
            className={isCurrLocale(item, locale) ? 'active' : null}
            locale={item}
            key={item}
            hrefLang={item}
            pageType={pageTypes.home}
            alt={alt}
          >
            {item}
          </StyledLocale>
        ))}
      </StyledList>
    </StyledSection>
  );
};

LocaleSwitch.propTypes = {
  alt: PropTypes.bool
};

export default memo(LocaleSwitch);
