import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useRouter } from '../lib/hooks';
import { getLocaleDefault, getLocales } from '../lib/locale';
import { isHome, pageTypes } from '../lib/page';

/*
    URL structure:

    /slug - default locale
    /locale/slug - other locales
*/

const defaultLocale = getLocaleDefault();

const LinkLocale = ({ slug, locale, children, pageType, ...rest }) => {
  const {
    match: { params }
  } = useRouter();
  const destinationLocale = locale || params.locale; //if no locale point to current page locale
  const destinationUrl = slug || params.slug || ''; //if no slug point to current page slug else empty string

  const paramSlug = !isHome(pageType) ? `/${destinationUrl}` : '';
  const paramLocale =
    destinationLocale && destinationLocale !== defaultLocale
      ? `/${destinationLocale}`
      : '';

  return (
    <NavLink to={`${paramLocale}${paramSlug}`} {...rest}>
      {children}
    </NavLink>
  );
};

LinkLocale.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.oneOf(getLocales()),
  pageType: PropTypes.oneOf(Object.values(pageTypes)),
  slug: PropTypes.string
};

export default LinkLocale;
