import desireFont from '../assets/DesireBasic.woff';
import typewriterFont from '../assets/TypewriterCondensed.woff';
import typewriterBoldFont from '../assets/TypewriterCondensedBold.woff';

const fontWeights = {
  regular: 400,
  bold: 'bold',
};

export const fontFaces = [
  {
    name: 'Desire-Basic',
    location: desireFont,
    weight: fontWeights.regular,
    fallback: 'serif',
  },
  {
    name: 'Typewriter_Condensed',
    location: typewriterFont,
    weight: fontWeights.regular,
    fallback: 'monospace',
  },
  {
    name: 'Typewriter_Condensed',
    location: typewriterBoldFont,
    weight: fontWeights.bold,
    fallback: 'monospace',
  },
];

const typographyDefaults = {
  baseFontSize: 18,
  baseFontSizeMobile: 16,
  baseLineHeight: 1.2,
  weights: fontWeights,
  families: {
    title: `${fontFaces[0].name}, ${fontFaces[0].fallback}`,
    body: `${fontFaces[1].name}, ${fontFaces[1].fallback}`,
  },
};

const sizing = {
  scale100: '1.2rem',
  scale200: '2.5rem',
  scale300: '3rem',
  scale400: '4rem',
  scale500: '5rem',
  scale600: '7rem',
  scale700: '22rem',
};

export default {
  breakpoints: {
    large: '1300px',
    medium: '1100px',
    small: '600px',
  },
  colors: {
    dark: '#1D0004',
    default: '#544C4D',
    primary: 'rgb(161,36,45)',
    primaryFaded: 'rgba(161,36,45,.2)',
    secondary: '#FDC84A',
    tertiary: '#62C605',
  },
  sizing,
  transitions: {
    duration: '.35s',
    durationMedium: '.5s',
    durationLong: '.75s',
    durationExtraLong: '1.5s',
    easing: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)' /* easeInOutSine */,
    easingAlt: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)' /* easeInSine */,
  },
  typography: {
    ...typographyDefaults,
    font100: `${12 / typographyDefaults.baseFontSize}rem`,
    font200: `${14 / typographyDefaults.baseFontSize}rem`,
    font300: '1rem',
    font400: `${20 / typographyDefaults.baseFontSize}rem`,
    font500: `${24 / typographyDefaults.baseFontSize}rem`,
    font600: `${34 / typographyDefaults.baseFontSize}rem`,
    font700: `${40 / typographyDefaults.baseFontSize}rem`,
    font800: `${50 / typographyDefaults.baseFontSize}rem`,
  },
  animations: {
    fadeIn: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    fadeUp: {
      from: {
        opacity: 0,
        transform: `translateY(${sizing.scale400})`,
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    scaleBottom: {
      from: {
        transform: 'scaleY(1)',
        transformOrigin: '0 bottom',
      },
      to: {
        transform: 'scaleY(0)',
        transformOrigin: '0 bottom',
      },
    },
    scaleLeft: {
      from: {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
      to: {
        transform: 'scaleX(0)',
        transformOrigin: '0 0',
      },
    },
    scaleRight: {
      from: {
        transform: 'scaleX(1)',
        transformOrigin: 'right 0',
      },
      to: {
        transform: 'scaleX(0)',
        transformOrigin: 'right 0',
      },
    },
    scaleTop: {
      from: {
        transform: 'scaleY(1)',
        transformOrigin: '0 0',
      },
      to: {
        transform: 'scaleY(0)',
        transformOrigin: '0 0',
      },
    },
  },
};
