import React from 'react';
import styled from 'styled-components';
import { useSpring, config } from 'react-spring';

import { useStateContext } from '../lib/context';

import { Modal, ModalClose, ModalWrap } from './Modal';

const ModalIframe = styled.iframe`
  display: block;
  height: 100%;
  width: 100%;
`;

export const ModalButton = ({ children, className = '' }) => {
  const [_, dispatch] = useStateContext();

  return (
    <a
      role="button"
      className={className}
      onClick={() =>
        dispatch({
          type: 'toggleReservations',
          open: true,
        })
      }
    >
      {children}
    </a>
  );
};
const MODAL_ANIM = {
  from: {
    opacity: 0,
    visibility: 'hidden',
  },
  to: {
    opacity: 1,
    visibility: 'visible',
  },
};

export const ModalContent = () => {
  const [
    {
      settings: { reservationsUrl },
      showReservations,
    },
    dispatch,
  ] = useStateContext();
  const modalAnim = useSpring({
    from: MODAL_ANIM.from,
    to: showReservations ? MODAL_ANIM.to : MODAL_ANIM.from,
    config: config.gentle,
  });

  return (
    <Modal style={modalAnim}>
      <ModalClose
        onClick={() =>
          dispatch({
            type: 'toggleReservations',
            open: false,
          })
        }
      >
        close
      </ModalClose>
      <ModalWrap>
        <ModalIframe src={reservationsUrl} />
      </ModalWrap>
    </Modal>
  );
};
