import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import theme from '../theme';
import { useStateContext } from '../lib/context';
import {
  useAnimationScroll,
  useScrollButton,
  useImageLoader,
} from '../lib/hooks';
import { removeProtocol } from '../lib/page';

import ButtonIcon from './ButtonIcon';
import SocialNav from './SocialNav';

import mask from '../assets/mask.png';
import maskHide from '../assets/mask-alt.png';

const animationDelay = 500; //in ms
const animationDuration = 2300; //in ms

const animDetails = {
  from: {
    maskPosition: '0 0',
    maskSize: '100% 220%',
  },
  to: {
    maskPosition: '100% 100%',
    maskSize: '300% 220%',
  },
};

const maskAppearFallback = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const maskAppear = keyframes`
  0% {
    opacity: 0;
    mask-position: ${animDetails.from.maskPosition};
    mask-size: ${animDetails.from.maskSize};
  }

  1% {
    mask-position: ${animDetails.from.maskPosition};
    mask-size: ${animDetails.from.maskSize};
    opacity: 1;
  }

  100% {
    mask-position: ${animDetails.to.maskPosition};
    mask-size: ${animDetails.to.maskSize};
  }
`;

const StyledSection = styled.section`
  margin-bottom: -5vh;
  opacity: 0;
  position: relative;

  @supports (mask-size: 100% 220%) {
    mask-image: url(${mask});
    mask-position: ${animDetails.from.maskPosition};
    mask-size: ${animDetails.from.maskSize};
  }

  &.showanim {
    animation-name: ${maskAppearFallback};
    animation-delay: ${animationDelay}ms;
    animation-duration: ${animationDuration}ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

    @supports (mask-size: 100% 220%) {
      animation-name: ${maskAppear};
      opacity: 1;
    }
  }

  &.animated {
    mask-image: none;
  }
`;

const ImagesContainer = styled.div`
  height: 110vh;
  mask-image: url(${maskHide});
  pointer-events: none;
`;

const StyledImg = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%;
`;

const StyledSocial = styled(SocialNav)`
  bottom: calc(10vh + ${theme.sizing.scale200});
  position: absolute;
  right: ${theme.sizing.scale300};
  z-index: 10;

  @media (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const StyledTakeAway = styled(ButtonIcon)`
  color: ${({ scrolled }) => (scrolled ? theme.colors.default : '#fff')};
  bottom: ${theme.sizing.scale200};
  left: ${theme.sizing.scale300};
  padding: 0;
  position: fixed;
  transform: rotate(270deg) translateY(100%);
  transform-origin: 0 bottom;
  transition: color ${theme.transitions.duration} ${theme.transitions.easing}
    ${theme.transitions.duration};

  @media (max-width: ${theme.breakpoints.medium}) {
    bottom: calc(10vh + ${theme.sizing.scale100});
    color: #fff;
    left: ${theme.sizing.scale100};
    position: absolute;
  }

  svg {
    pointer-events: none;
    transform: rotate(90deg);
  }
`;

const TakeAwayButton = ({ display, scrolled, slug }) => {
  const [
    {
      settings: { takeAwayLabel },
    },
  ] = useStateContext();
  const scrollTo = useScrollButton();

  return display ? (
    <StyledTakeAway
      scrolled={scrolled}
      icon="bike"
      href={`#${slug}`}
      onClick={scrollTo}
    >
      {takeAwayLabel}
    </StyledTakeAway>
  ) : null;
};

TakeAwayButton.propTypes = {
  display: PropTypes.bool.isRequired,
  scrolled: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
};

const intervalDuration = animationDelay + animationDuration;

const Hero = ({ images, onAnimationEnd = () => {}, takeAwayButton }) => {
  const heroImageLoaded = useImageLoader(removeProtocol(images[0].image));
  const [animated, setAnimated] = useState(false);
  const [active, setActive] = useState(0);
  const [maskSize, setMaskSize] = useState('');
  const scrolled = useAnimationScroll();
  const isScrolled = scrolled > window.innerHeight / 10;
  const [maskedTakeAway, setMaskedTakeAway] = useState(false);
  const maskPosition =
    window.innerWidth < window.innerHeight
      ? `${-scrolled}px ${-scrolled / 1.2}px`
      : `${-scrolled * 2}px ${-scrolled / 1.2}px`;
  const getTakeAwayDetails = (masked = false) => ({
    scrolled: isScrolled,
    display:
      takeAwayButton.display && (masked ? maskedTakeAway : !maskedTakeAway),
    slug: takeAwayButton.slug,
  });

  useEffect(() => {
    let interval;
    const onResize = () => {
      if (window.innerWidth <= parseInt(theme.breakpoints.medium)) {
        setMaskedTakeAway(true);
      } else {
        setMaskedTakeAway(false);
      }

      setMaskSize(`${window.innerWidth * 2}px ${window.innerHeight * 2.3}px`);
    };

    if (images.length > 1) {
      interval = setInterval(() => {
        const next = active + 1;
        setActive(next > images.length - 1 ? 0 : next);
      }, intervalDuration);
    }

    if (heroImageLoaded && !animated) {
      setTimeout(() => {
        onAnimationEnd();
        setAnimated(true);
      }, intervalDuration);
    }

    onResize();

    window.addEventListener('resize', onResize, true);

    return () => {
      images.length > 1 && clearInterval(interval);
      window.removeEventListener('resize', onResize, true);
    };
  }, [images, scrolled, heroImageLoaded]);

  return (
    <StyledSection
      className={`${heroImageLoaded ? 'showanim' : ''} ${
        animated ? 'animated' : ''
      }`}
    >
      <ImagesContainer
        style={{
          WebkitMaskPosition: maskPosition,
          maskPosition,
          WebkitMaskSize: maskSize,
          maskSize,
        }}
      >
        {images.map(({ image, label }, i) => (
          <StyledImg
            src={removeProtocol(image)}
            alt={label}
            active={active === i}
            key={i}
          />
        ))}
        <StyledSocial />
        <TakeAwayButton {...getTakeAwayDetails(true)} />
      </ImagesContainer>
      <TakeAwayButton {...getTakeAwayDetails(false)} />
    </StyledSection>
  );
};

Hero.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape(
      {
        image: PropTypes.string,
        label: PropTypes.string,
      }.isRequired
    )
  ).isRequired,
  onAnimationEnd: PropTypes.func,
  takeAwayButton: PropTypes.shape(
    {
      slug: PropTypes.string,
      display: PropTypes.bool,
    }.isRequired
  ),
};

export default memo(Hero);
