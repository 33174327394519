import React, { memo, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useStateContext } from '../lib/context';

import theme from '../theme';
import { useMarkdownParser } from '../lib/hooks';

const StyledWrapper = styled(animated.article)`
  color: ${theme.colors.default};
  position: relative;

  @media (max-width: ${theme.breakpoints.small}) {
    padding-bottom: ${theme.sizing.scale100};

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const StyledText = styled.div`
  columns: ${({ columns }) => columns};
  column-gap: ${theme.sizing.scale300};
  font-size: ${theme.typography.font500};

  @media (max-width: ${theme.breakpoints.small}) {
    columns: 1;
    overflow: hidden;
    mask-image: ${({ open }) =>
      open
        ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))'
        : 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0))'};
    max-height: ${({ open }) => (open ? '9999999em' : '8em')};
  }

  p {
    break-inside: avoid;
    padding-bottom: ${theme.sizing.scale100};

    &:last-child {
      padding-bottom: 0;
    }
  }

  strong {
    font-weight: ${theme.typography.weights.bold};
  }

  em {
    color: ${theme.colors.primary};
  }

  a {
    color: ${theme.colors.tertiary};
    text-decoration: underline;
    transition: color ${theme.transitions.duration} ${theme.transitions.easing};

    &:hover,
    &:focus {
      color: ${theme.colors.secondary};
    }
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  font-size: ${theme.typography.font500};
  text-decoration: underline;
`;

const Paragraph = ({ content, columns, truncate, ...rest }) => {
  const [open, setOpen] = useState(true);
  const __html = useMarkdownParser(content);
  const [
    {
      settings: { readMoreLabel }
    }
  ] = useStateContext();

  useLayoutEffect(() => {
    const truncateText = () => {
      if (truncate) {
        if (window.innerWidth <= parseInt(theme.breakpoints.small)) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }
    };

    window.addEventListener('resize', truncateText, true);

    return () => window.removeEventListener('resize', truncateText, true);
  }, [truncate]);

  return (
    <StyledWrapper {...rest}>
      <StyledText
        dangerouslySetInnerHTML={{ __html }}
        columns={columns}
        open={open}
      />
      {truncate && (
        <StyledButton
          visible={!open}
          onClick={() => setOpen(true)}
          aria-hidden="true"
        >
          {readMoreLabel}
        </StyledButton>
      )}
    </StyledWrapper>
  );
};

Paragraph.propTypes = {
  content: PropTypes.string.isRequired,
  columns: PropTypes.number.isRequired,
  truncate: PropTypes.bool
};

export default memo(Paragraph);
